<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { AppBtn, HeadlessDialogPanel } from "#components";
import { PostType } from "#imports";

const emit = defineEmits<{
  reset: [];
  "update:isOpen": [value: boolean];
  "update:modelValue": [value: Record<PostType, string[]>];
}>();

const props = withDefaults(
  defineProps<{
    isOpen?: boolean;

    modelValue?: Record<PostType, string[]>;
    categories?: PostType[];

    class?: HTMLAttributes["class"];
    tags?: Record<PostType, TagItem[]>;
  }>(),
  {
    modelValue: () => ({
      inspiration: [],
      architect: [],
      professional: [],
      relaxation: [],
      contractor: [],
      project: [],
    }),
    categories: () => [
      PostType.Inspiration,
      PostType.Architect,
      PostType.Professional,
      PostType.Relaxation,
      PostType.Contractor,
      PostType.Project,
    ],
  }
);

const isOpen = useVModel(props, "isOpen", emit, {
  passive: true,
  defaultValue: false,
});

const model = useVModel(props, "modelValue", emit, {
  deep: true,
  clone: true,
  passive: true,
  shouldEmit: () => false,
  defaultValue: {
    inspiration: [],
    architect: [],
    professional: [],
    relaxation: [],
    contractor: [],
    project: [],
  },
});

whenever(
  isOpen,
  () => (model.value = JSON.parse(JSON.stringify(props.modelValue)))
);

const setModelValue = (type: PostType, value: string, checked: boolean) => {
  if (!model.value) {
    model.value = {
      inspiration: [],
      architect: [],
      professional: [],
      relaxation: [],
      contractor: [],
      project: [],
    };
  }

  if (!model.value[type]) {
    model.value[type] = [];
  }

  if (checked) {
    if (model.value[type].includes("OTHER")) {
      model.value[type] = model.value[type].filter((tag) => tag !== "OTHER");
    }

    model.value[type].push(value);
  } else {
    model.value[type] = model.value[type].filter((tag) => tag !== value);
  }
};

const { isMobile } = useUserDevice();
const { currentPalette } = useTheme();

const reset = () => {
  emit("reset");
  isOpen.value = false;
};

const confirm = () => {
  emit("update:modelValue", model.value);
  isOpen.value = false;
};

const PostFilterDialogFooter = () => {
  return h(
    "div",
    {
      class: [
        "post-filter-dialog__panel-footer",
        "sticky inset-x-0 bottom-0",
        "p-8 h-28",
        "bg-grey-50",
        "flex items-center justify-center gap-6",
      ],
    },
    [
      h(AppBtn, { onClick: () => reset() }, { default: () => "Usuń filtry" }),
      h(
        AppBtn,
        { onClick: () => confirm(), variant: "solid" },
        { default: () => "Zastosuj wybrane" }
      ),
    ]
  );
};

const { getPostTypeTitlePlural } = usePostTypes();
</script>

<template>
  <Teleport to="body">
    <HeadlessTransitionRoot :show="isOpen" as="template">
      <HeadlessDialog
        class="post-filter-dialog relative z-40 text-sm pt-[4.125rem] lg:pt-0"
        @close="isOpen = false"
        :class="props.class"
        v-bind="$attrs"
      >
        <HeadlessTransitionChild as="template">
          <div
            class="post-filter-dialog__overlay fixed inset-0 lg:backdrop-blur-sm cursor-pointer"
            :class="[isMobile ? 'bg-white' : 'lg:bg-black/80']"
            aria-hidden="true"
          ></div>
        </HeadlessTransitionChild>

        <HeadlessTransitionChild as="template">
          <component
            :is="isMobile ? HeadlessDialogPanel : 'div'"
            class="fixed inset-0 w-screen overflow-y-auto"
          >
            <div
              v-if="isMobile"
              class="absolute inset-0 -z-20"
              :class="[currentPalette.bg]"
            ></div>

            <div
              v-if="isMobile"
              class="fixed inset-x-0 bottom-0 h-1/2 bg-grey-50 -z-10"
            ></div>

            <div
              class="post-filter-dialog__header sticky top-0 z-10 lg:hidden h-[4.125rem] flex flex-col items-stretch justify-center bg-white select-none"
            >
              <div class="post-filter-dialog__header-container container">
                <div
                  class="post-filter-dialog__header-wrapper flex items-center justify-between gap-4"
                >
                  <div
                    class="post-filter-dialog__header-section post-filter-dialog__header-section--start flex items-center gap-4"
                  >
                    <img
                      src="~/assets/img/logo-mobile.svg?url"
                      alt="Logo"
                      class="h-7 w-auto block"
                      width="213"
                      height="28"
                    />
                  </div>

                  <div
                    class="post-filter-dialog__header-section post-filter-dialog__header-section--end flex items-center gap-4"
                  >
                    <button
                      class="post-filter-dialog__close post-filter-dialog__close--mobile w-10 h-10 flex items-center justify-center"
                      title="Zamknij"
                      @click="isOpen = false"
                    >
                      <Icon name="ns:close-big" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="flex flex-col min-h-[calc(100dvh_-_4.125rem)] lg:min-h-full w-full lg:max-w-[72.5rem] mx-auto items-center justify-center pt-12 lg:py-8"
            >
              <component
                :is="isMobile ? 'div' : HeadlessDialogPanel"
                :class="[
                  'post-filter-dialog__panel',
                  'relative w-full min-h-[calc(100dvh_-_4.125rem_-_3rem)] lg:min-h-[38rem]',
                  'bg-white',
                  'flex flex-col items-stretch justify-stretch',
                  'overflow-hidden',
                  'rounded-t-[2.5rem]',
                  'lg:rounded-tr-none lg:rounded-b-[2.5rem] lg:rounded-s-[2.5rem]',
                  'flex flex-col items-stretch justify-stretch gap-4',
                ]"
              >
                <div
                  class="post-filter-dialog__panel-header pt-6 px-5 lg:pt-8 lg:px-8 flex items-center justify-end lg:justify-between gap-4"
                >
                  <div class="lg:h-10 flex items-center">
                    <h2
                      class="lowercase lg:normal-case text-[1.75rem] leading-[2.25rem] font-display tracking-[0.3125rem] lg:font-body lg:text-2xl lg:leading-9 lg:font-semibold lg:tracking-normal"
                    >
                      Filtruj
                    </h2>
                  </div>

                  <button
                    class="post-filter-dialog__close w-10 h-10 items-center justify-center hidden lg:flex"
                    title="Zamknij"
                    @click="isOpen = false"
                  >
                    <Icon name="ns:close-big" />
                  </button>
                </div>

                <div
                  class="post-filter-dialog__panel-content grow px-5 lg:px-8"
                >
                  <div
                    class="post-filter-dialog__panel-wrapper flex flex-col items-stretch gap-6"
                  >
                    <template v-for="type in PostType">
                      <PostFilterCategory
                        v-if="props.categories.includes(type)"
                        :title="getPostTypeTitlePlural(type)"
                      >
                        <PostFilterCheckbox
                          @update:model-value="() => (model[type] = [])"
                          :model-value="model[type].filter(Boolean).length <= 0"
                        >
                          Wszystkie
                        </PostFilterCheckbox>

                        <template v-if="tags?.[type]">
                          <PostFilterCheckbox
                            v-for="tag in tags[type]"
                            @update:model-value="(value) => setModelValue(type, tag.title!, value)"
                            :model-value="model[type].includes(tag.title!)"
                          >
                            {{ tag.title }}
                          </PostFilterCheckbox>
                        </template>

                        <PostFilterCheckbox
                          @update:model-value="
                            (value) => (model[type] = value ? ['OTHER'] : [])
                          "
                          :model-value="model[type].includes('OTHER')"
                        >
                          Pozostałe
                        </PostFilterCheckbox>
                      </PostFilterCategory>
                    </template>
                  </div>
                </div>

                <PostFilterDialogFooter v-if="!isMobile" />
              </component>
            </div>

            <PostFilterDialogFooter v-if="isMobile" />
          </component>
        </HeadlessTransitionChild>
      </HeadlessDialog>
    </HeadlessTransitionRoot>
  </Teleport>
</template>
