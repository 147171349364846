export const usePostTags = () => {
  const { getItems } = useDirectusItems();

  const getFilterTags = (type?: MaybeRef<PostType>) => {
    type = unref(type);

    return getItems<TagItem>({
      collection: "tags",
      params: {
        filter: {
          filters: type ? { _contains: type } : { _nnull: true },
        },
      },
    });
  };

  return { getFilterTags };
};
