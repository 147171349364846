export interface CtaContentItem extends Record<string, any> {
  id?: string;
  page?: PageType;
  pre_title?: string;
  title?: string;
  content?: string;
  action_prompt?: string;
  action_button?: string;
}

export const useCta = () => {
  const { getItems } = useDirectusItems();

  const getCtaContent = async (page: MaybeRef<PageType>) => {
    return await getItems<CtaContentItem>({
      collection: "cta",
      params: { limit: 1, offset: 0, filter: { page: { _eq: unref(page) } } },
    })
      .then((response) => response?.[0] || null)
      .catch(() => null);
  };

  return { getCtaContent };
};
