export const usePostListing = (
  options: {
    type?: MaybeRef<PostType>;
    page?: MaybeRef<number | `${number}`>;
    limit?: MaybeRef<number | `${number}`>;
    filter?: MaybeRef<Record<PostType, string[]>>;
    bounds?: MaybeRef<google.maps.LatLngBoundsLiteral | undefined>;
    strategicFirst?: MaybeRef<boolean | undefined>;
  } = {}
) => {
  const type = computed(() => unref(options.type) || undefined);
  const page = computed(() => unref(options.page) || 1);
  const limit = computed(() => unref(options.limit) || 20);
  const filter = computed(() => unref(options.filter) || {});
  const bounds = computed(() => unref(options.bounds));

  const strategicFirst = computed(() => unref(options.strategicFirst) || false);

  const items = shallowRef<PostItem[]>([]);
  const totalPages = ref(0);

  const key = ref(0);

  const { defaultPosts, postFieldPresets, getPosts } = usePosts();
  const { tags } = usePostFilter();

  const postFilter = computed(() => {
    const filterValue: { _or: Record<string, unknown>[] } = { _or: [] };

    const geometryFilter = bounds.value
      ? {
          _and: [
            { location_map_enabled: { _eq: true } },
            {
              location_map_marker: {
                _intersects_bbox: {
                  type: "Feature",
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [bounds.value.west, bounds.value.north],
                        [bounds.value.west, bounds.value.south],
                        [bounds.value.east, bounds.value.south],
                        [bounds.value.east, bounds.value.north],
                        [bounds.value.west, bounds.value.north],
                      ],
                    ],
                  },
                },
              },
            },
          ],
        }
      : undefined;

    for (const [key, value] of Object.entries<string[]>(filter.value) as [
      PostType,
      string[]
    ][]) {
      if (value.includes("OTHER")) {
        filterValue._or.push({
          _and: [
            { type: { _eq: key } },
            {
              _or: [
                {
                  tags: {
                    tags_id: {
                      title: {
                        _nin: tags.value[key].map((item) => item.title!),
                      },
                    },
                  },
                },
                { tags: { tags_id: { title: { _null: true } } } },
              ],
            },
            ...(geometryFilter ? [geometryFilter] : []),
          ],
        });
      } else if (value.length > 0) {
        filterValue._or.push({
          _and: [
            { type: { _eq: key } },
            { tags: { tags_id: { title: { _in: value } } } },
            ...(geometryFilter ? [geometryFilter] : []),
          ],
        });
      } else {
        filterValue._or.push({
          _and: [
            { type: { _eq: key } },
            ...(geometryFilter ? [geometryFilter] : []),
          ],
        });
      }
    }

    return filterValue;
  });

  watchDeep(
    [type, postFilter, bounds],
    () => {
      items.value = [];
      totalPages.value = 0;
      key.value++;
    },
    { immediate: true }
  );

  return useAsyncData<{
    data: NonNullable<PostItem>[];
    meta?: {
      total_count?: number;
      filter_count?: number;
      total_pages?: number;
    };
  }>(
    unref(type)
      ? `posts:${unref(type)}:${unref(page)}:${unref(limit)}:key(${key.value})`
      : `posts:all:${unref(page)}:${unref(limit)}:key(${key.value})`,
    async () => {
      return await getPosts({
        type: unref(type),
        page: isDefined(page) ? Number(unref(page)) : 1,
        limit: isDefined(limit) ? Number(unref(limit)) : 20,
        fields: postFieldPresets.listing,
        filter: postFilter.value,
        sort: unref(strategicFirst) ? "-strategic" : "-date_published",
      })
        .then((response) => response || defaultPosts)
        .catch(() => defaultPosts);
    },
    {
      server: false,
      default: () => defaultPosts,
      watch: [type, page, limit, postFilter],
      dedupe: "defer",
      transform: (response) => {
        const newPosts =
          response.data?.filter(
            (post) => !items.value.find((item) => item.id === post.id)
          ) || [];

        items.value = [
          ...items.value,
          ...newPosts.sort((a, b) =>
            unref(strategicFirst)
              ? a.strategic && !b.strategic
                ? -1
                : b.strategic && !a.strategic
                ? 1
                : Math.random() - 0.5
              : 0
          ),
        ].sort((a, b) =>
          unref(strategicFirst)
            ? 0
            : new Date(b.date_published).getTime() -
              new Date(a.date_published).getTime()
        );

        totalPages.value = Math.ceil(
          (response.meta?.filter_count || 0) / (Number(unref(limit)) || 1)
        );

        return {
          ...response,
          data: items.value,
          meta: {
            ...response.meta,
            total_pages: totalPages.value,
          },
        };
      },
    }
  );
};
