export interface SliderItem extends Record<string, any> {
  id?: string;
  sort?: number;
  image?: FileItem;
  title?: string;
  link?: string;
  pages?: PageType[];
  color?: "blue" | "rose" | "green" | "yellow" | "darkblue" | "grey";
}

export const usePageSlider = () => {
  const { getItems } = useDirectusItems();

  const getSlides = async (page?: MaybeRef<PageType>) => {
    page = unref(page);

    return await getItems<SliderItem>({
      collection: "slider",
      params: {
        limit: -1,
        offset: 0,
        fields: ["*", "image.*"],
      },
    }).then((response) => {
      return page
        ? response?.filter((item) => item.pages?.includes(page))
        : response;
    });
  };

  return { getSlides };
};
