<script setup lang="ts">
import type { Swiper as SwiperComponent } from "swiper/vue";
import type { SliderItem } from "~/composables/usePageSlider";

import { Autoplay, Keyboard, Pagination } from "swiper/modules";

const { currentPalette } = useTheme();

const props = withDefaults(
  defineProps<{
    items: SliderItem[];
    options?: PropType<typeof SwiperComponent>;
  }>(),
  { items: () => [] }
);

const { isMobile, useRemValue, width } = useUserDevice();

const spaceBetweenRem = useRemValue(2.5);

const outerContainerPadding = useRemValue(2.5);
const innerContainerPadding = useRemValue(10);

const slidesPerView = computed(() => {
  if (isMobile.value) {
    return 1;
  } else {
    const containerWidth =
      Math.min(width.value, 1920) - outerContainerPadding.value * 2;
    const slideWidth = containerWidth - innerContainerPadding.value * 2;

    return containerWidth / slideWidth;
  }
});

const spaceBetween = computed(() =>
  isMobile.value ? 0 : spaceBetweenRem.value
);

const renderBullet = (index: number, className: string) => {
  const activeColors = {
    blue: String.raw`[&.page-slider\_\_pagination-bullet--active]:!bg-blue`,
    rose: String.raw`[&.page-slider\_\_pagination-bullet--active]:!bg-rose`,
    green: String.raw`[&.page-slider\_\_pagination-bullet--active]:!bg-green`,
    yellow: String.raw`[&.page-slider\_\_pagination-bullet--active]:!bg-yellow`,
    darkblue: String.raw`[&.page-slider\_\_pagination-bullet--active]:!bg-darkblue`,
    grey: String.raw`[&.page-slider\_\_pagination-bullet--active]:!bg-grey`,
    copperfield: String.raw`[&.page-slider\_\_pagination-bullet--active]:!bg-copperfield`,
    mongoose: String.raw`[&.page-slider\_\_pagination-bullet--active]:!bg-mongoose`,
  };

  const colors = {
    blue: String.raw`!border-blue`,
    rose: String.raw`!border-rose`,
    green: String.raw`!border-green`,
    yellow: String.raw`!border-yellow`,
    darkblue: String.raw`!border-darkblue`,
    grey: String.raw`!border-grey`,
    copperfield: String.raw`!border-copperfield`,
    mongoose: String.raw`!border-mongoose`,
  };

  const itemColor = props.items[index].color || "blue";

  const activeClass = activeColors[itemColor];
  const generalClass = colors[itemColor];

  return `<span class="${className} ${generalClass} ${activeClass}"><span class="sr-only">${
    index + 1
  }</span></span>`;
};
</script>

<template>
  <section
    v-if="props.items.length > 0"
    class="page-slider mb-4 lg:mb-5 select-none"
  >
    <div class="page-slider__container container">
      <div
        class="page-slider__wrapper relative !pt-[67.988668555%] lg:!pt-[34.264705882%]"
      >
        <Swiper
          :modules="[Autoplay, Keyboard, Pagination]"
          :autoplay="{ delay: 3000, pauseOnMouseEnter: true }"
          :keyboard="{ enabled: true }"
          :pagination="{
            enabled: true,
            clickable: true,
            type: 'bullets',
            el: '.page-slider__pagination',
            clickableClass: 'page-slider__pagination--clickable',
            currentClass: 'page-slider__pagination--current',
            lockClass: 'page-slider__pagination--lock',
            totalClass: 'page-slider__pagination--total',
            hiddenClass: 'page-slider__pagination--hidden',
            modifierClass: 'page-slider__pagination--',
            verticalClass: 'page-slider__pagination--vertical',
            horizontalClass: 'page-slider__pagination--horizontal',
            progressbarFillClass: 'page-slider__pagination--progressbar-fill',
            paginationDisabledClass: 'page-slider__pagination--disabled',
            progressbarOppositeClass:
              'page-slider__pagination--progressbar-opposite',
            bulletElement: 'span',
            bulletClass:
              'page-slider__pagination-bullet block w-6 h-6 rounded-full border cursor-pointer',
            bulletActiveClass: 'page-slider__pagination-bullet--active',
            renderBullet,
          }"
          :slides-per-view="slidesPerView"
          :space-between="spaceBetween"
          centered-slides
          :loop="props.items.length >= 3"
          class="!absolute !inset-0 !w-full !h-full"
          v-bind="props.options"
          tabindex="-1"
        >
          <SwiperSlide
            v-for="(item, index) in props.items"
            :class="[currentPalette.bg]"
          >
            <PageSliderItem v-bind="{ item }" loading="eager" />
          </SwiperSlide>

          <div
            class="page-slider__pagination p-4 absolute bottom-0 left-1/2 -translate-x-1/2 z-10 hidden lg:flex items-center justify-center gap-3"
          ></div>
        </Swiper>
      </div>
    </div>
  </section>
</template>
