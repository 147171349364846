<script setup lang="ts">
const emit = defineEmits<{ load: [] }>();
defineProps<{ container?: boolean }>();
</script>

<template>
  <section class="post-loader">
    <div class="post-loader__container" :class="{ container }">
      <div class="post-loader__wrapper flex items-center justify-center gap-4">
        <AppBtn @click="emit('load')">
          <slot>
            <span>Zobacz więcej</span>
          </slot>
        </AppBtn>
      </div>
    </div>
  </section>
</template>
